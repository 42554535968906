import React, { PureComponent } from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import classNames from 'classnames/bind';
import styles from './FormEmbed.module.scss';

const cx = classNames.bind(styles);
const stripFormTags = html => {
  let result = html.replace(/style="[^"]*"/g, '');
  return result;
};

export default class FormEmbed extends PureComponent {
  static ID = 0;
  hasSetIframeHeight = false;
  state = { id: null };

  componentDidMount() {
    FormEmbed.ID++;
    this.setState({ id: FormEmbed.ID });

    setTimeout(() => {
      const frame = this.formContainer.querySelector('iframe');
      if (frame) {
        this.heightInterval = setInterval(this.setIframeHeight, 1000);
      }
    }, 1);
  }

  componentWillUnmount() {
    clearInterval(this.heightInterval);
  }

  setIframeHeight = () => {
    const frame = this.formContainer.querySelector('iframe');
    if (!frame) {
      return;
    }
    if (!this.hasSetIframeHeight) {
      frame.style.height = `1445px`;
    }
    if (!this.hasSetIframeHeight) {
      this.hasSetIframeHeight = true;
    }
  };

  render() {
    const { primary } = this.props;
    const { id } = this.state;
    const { source_code } = primary;
    const theme = primary && primary.theme ? primary.theme : 'black';

    return (
      <Section className={`${theme} ${cx({ section: true, [theme]: true })}`}>
        <Container>
          <Columns mobile centered>
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              <article
                ref={r => (this.formContainer = r)}
                id={`formembed-${id}`}
                className="form-embed-inline"
                dangerouslySetInnerHTML={{
                  __html:
                    source_code.text.indexOf('<iframe') >= 0
                      ? source_code.text
                      : stripFormTags(source_code.text)
                }}
              />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    );
  }
}
