import React, { PureComponent } from "react";
import { renderText, renderHtml, PrismicLink } from "utils/renderHelpers";
import { renderFluidImage } from "utils/imageHelpers";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";

import classNames from "classnames/bind";
import styles from "./SectionCallouts.module.scss";
import Waypoint from "react-waypoint";
import posed from "react-pose";
import { sine } from "utils/easing";

const cx = classNames.bind(styles);

const Wrapper = posed.div({
  active: {
    staggerChildren: 100
  }
});

const Block = posed.article({
  inactive: {
    opacity: 0,
    y: 20
  },
  active: {
    opacity: 1,
    y: 0,
    beforeChildren: true,
    staggerChildren: 250,
    transition: {
      type: "tween",
      duration: 400,
      ease: sine.out
    }
  }
});

const Overlay = posed.div({
  inactive: {
    x: `0`
  },
  active: {
    x: `100%`,
    transition: {
      type: `tween`,
      ease: sine.out,
      duration: 400
    }
  }
});

const renderImage = (image, theme, button_label, button_link) => {
  if (!image || !image.url) {
    return null;
  }
  const isLink = button_link && button_label;
  const renderedImage = (
    <figure className={`image ${styles.isImage}`}>
      {renderFluidImage(image, null, { maxWidth: [360, 360, 360, 475] })}
      <Overlay
        initialPose={`inactive`}
        className={`image-overlay ${cx({ [theme]: true, overlay: true })}`}
      />
    </figure>
  );

  return isLink ? (
    <PrismicLink className={styles.imageLink} link={button_link}>
      {renderedImage}
    </PrismicLink>
  ) : (
    renderedImage
  );
};

const renderItem = (
  {
    category,
    image,
    heading,
    description,
    button_label,
    button_link,
    anchor_id
  },
  theme,
  idx
) => {
  return (
    <Columns.Column
      className={`is-flex-tablet ${styles.column}`}
      mobile={{ size: 12 }}
      tablet={{ size: 4 }}
      key={`section-callout-${idx}`}
    >
      <Block initialPose={`inactive`} className={styles.callout}>
        <div>
          {renderText(category, `h5`, `has-text-weight-bold`)}
          {renderImage(image, theme, button_label, button_link)}
          {renderText(heading, `h3`, `has-text-weight-bold`)}
          {renderHtml(description)}
        </div>
        {button_link && (
          <PrismicLink
            label={button_label}
            link={button_link}
            anchor={anchor_id}
            className={styles.button}
          />
        )}
      </Block>
    </Columns.Column>
  );
};

export default class SectionCallouts extends PureComponent {
  state = { active: false, waypointDisabled: false };
  onWaypointEnter = () => {
    this.setState({ active: true, waypointDisabled: true });
  };
  render() {
    const { id, primary, items } = this.props;
    const { heading } = primary;
    const theme = primary.theme || `grey`;
    return (
      <Section
        className={`${theme} ${cx({ section: true, [theme]: true })}`}
        key={id}
      >
        <Waypoint
          onEnter={
            this.state.waypointDisabled ? undefined : this.onWaypointEnter
          }
          bottomOffset={`10%`}
          topOffset={`-10%`}
        >
          <Wrapper pose={this.state.active ? `active` : `inactive`}>
            <Container>
              <Columns mobile multiline>
                {heading && heading.text && (
                  <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                    {renderText(heading, `h4`)}
                  </Columns.Column>
                )}
                {items.map((item, idx) => renderItem(item, theme, idx))}
              </Columns>
            </Container>
          </Wrapper>
        </Waypoint>
      </Section>
    );
  }
}
