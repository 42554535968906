import React, { PureComponent } from "react";
import { Link } from "gatsby";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { renderText, renderHtml } from "utils/renderHelpers";

import styles from "./FAQList.module.scss";
import classNames from "classnames/bind";
import posed from "react-pose";
import uid from "utils/uid";
import { sine } from "utils/easing";

const cx = classNames.bind(styles);

const AccordionContainer = posed.article({});
const AccordionTop = posed.button({});
const AccordionBottom = posed.article({
  inactive: {
    maxHeight: 0,
    opacity: 0,
    transition: {
      opacity: { type: "tween", duration: 150, ease: sine.in },
      maxHeight: { type: "tween", duration: 300, ease: sine.out },
    },
  },
  active: {
    maxHeight: 1000,
    opacity: 1,
    transition: {
      maxHeight: { type: "tween", duration: 2000, ease: sine.out },
    },
  },
});

class AccordionItem extends PureComponent {
  state = { active: false };

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  render() {
    const { question, answer } = this.props;
    return (
      <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
        <AccordionContainer
          key="accContainer"
          className={styles.accordionItem}
          tabIndex="-1"
        >
          <AccordionTop
            className={styles.accordionQuestion}
            onClick={this.toggleActive}
            initialPose={"inactive"}
            pose={this.state.active ? "active" : "inactive"}
          >
            <Columns mobile>
              <Columns.Column
                mobile={{ size: 2 }}
                tablet={{ size: 1 }}
                className={`is-flex ${styles.flexColumn}`}
              >
                <i className="icon">
                  <FontAwesomeIcon
                    icon={this.state.active ? faMinus : faPlus}
                  />
                </i>
              </Columns.Column>
              <Columns.Column
                className={`is-flex ${styles.flexColumn}`}
                mobile={{ size: 10 }}
                tablet={{ size: 11 }}
              >
                {renderHtml(question, `h3`, styles.question)}
              </Columns.Column>
            </Columns>
          </AccordionTop>
          <AccordionBottom
            tabIndex="-1"
            className={styles.accordionAnswer}
            initialPose={"inactive"}
            pose={this.state.active ? "active" : "inactive"}
          >
            <Columns mobile>
              <Columns.Column
                mobile={{ size: 10, offset: 2 }}
                tablet={{ size: 11, offset: 1 }}
              >
                {renderHtml(answer)}
              </Columns.Column>
            </Columns>
          </AccordionBottom>
        </AccordionContainer>
      </Columns.Column>
    );
  }
}

const AccordionList = ({ items }) => (
  <React.Fragment>
    {items.map((item) => {
      const data = item.faq_item.document[0].data;
      const { question, answer } = data;
      return (
        <AccordionItem
          key={`accordion-${uid()}`}
          question={question}
          answer={answer}
        />
      );
    })}
  </React.Fragment>
);

const ColumnList = ({ items }) => (
  <React.Fragment>
    {items.map((item, idx) => {
      const data = item.faq_item.document[0].data;
      const { question, answer } = data;
      return (
        <Columns.Column
          mobile={{ size: 12 }}
          tablet={{ size: 4 }}
          key={`faqitem-${idx}`}
        >
          <article className={styles.item}>
            {renderHtml(question, `h3`, styles.question)}
            {renderHtml(answer)}
          </article>
        </Columns.Column>
      );
    })}
  </React.Fragment>
);

export default ({ primary, items }) => {
  const { heading, layout } = primary;
  const theme = primary.theme || `white`;
  return (
    <Section className={`${theme} ${cx({ section: true, [theme]: true })}`}>
      <Container>
        <Columns mobile multiline>
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            {renderText(heading, "h4")}
          </Columns.Column>
          {layout === "accordion" ? (
            <AccordionList items={items} />
          ) : (
            <ColumnList items={items} />
          )}
          {layout !== "accordion" && (
            <Columns.Column
              mobile={{ size: 12 }}
              tablet={{ size: 12 }}
              className={`has-text-centered`}
            >
              <Link to={`/faq`} title="FAQ" className={styles.button}>
                See full FAQ
              </Link>
            </Columns.Column>
          )}
        </Columns>
      </Container>
    </Section>
  );
};
