import React from "react";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { renderText } from "utils/renderHelpers";

import styles from "./SponsorGroup.module.scss";
import classNames from "classnames/bind";
import { renderFluidImage } from "utils/imageHelpers";

const cx = classNames.bind(styles);

const renderSponsor = sponsor => {
  if (!sponsor) {
    return null;
  }
  const doc = sponsor.document[0].data;
  const { name, url, logo } = doc;
  if (url && url.text && url.text !== "") {
    return (
      <a href={url.text} target={"_blank"} rel="noopener noreferrer">
        <figure>{renderFluidImage(logo, null, { alt: name.text })}</figure>
      </a>
    );
  }
  return <figure>{renderFluidImage(logo, null, { alt: name.text })}</figure>;
};

export default ({ primary, items }) => {
  const { heading, size, theme } = primary;
  return (
    <Section
      className={`${theme || `white`} ${cx({
        section: true,
        [theme || `white`]: true
      })}`}
    >
      <Container>
        <Columns mobile multiline className={styles.row}>
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            {renderText(heading, "h2")}
          </Columns.Column>
          {items.map((item, idx) => {
            const { heading, text_sponsor, sponsor } = item;
            if (!sponsor && !text_sponsor) {
              return null;
            }
            return (
              <Columns.Column
                className={styles.column}
                key={`sponsor-${idx}`}
                mobile={{ narrow: true }}
                tablet={{ narrow: true }}
              >
                <article className={cx({ sponsor: true, [size]: true })}>
                  {renderText(heading, "h4")}
                  {text_sponsor && text_sponsor.text
                    ? renderText(text_sponsor, "h5")
                    : renderSponsor(sponsor)}
                </article>
              </Columns.Column>
            );
          })}
        </Columns>
      </Container>
    </Section>
  );
};
