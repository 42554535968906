import { Link } from "gatsby";
import React, { PureComponent } from "react";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Section from "react-bulma-components/lib/components/section";
import posed from "react-pose";
import Waypoint from "react-waypoint";
import { sine } from "utils/easing";
import { renderFluidImage } from "utils/imageHelpers";
import { renderHtml, renderText } from "utils/renderHelpers";
import resolveLinkById from "utils/resolveLinkById";

import classNames from "classnames/bind";
import styles from "./CardList.module.scss";
const cx = classNames.bind(styles);

const Wrapper = posed.div({
  active: {
    transition: {
      staggerChildren: 100,
    },
  },
});

const Overlay = posed.div({
  inactive: {
    x: `0`,
  },
  active: {
    x: `100%`,
    transition: {
      type: `tween`,
      ease: sine.out,
      duration: 400,
      delay: 500,
    },
  },
});

const CardContent = posed.div({
  inactive: {
    opacity: 0,
    y: 15,
  },
  active: {
    opacity: 1,
    y: 0,
    transition: {
      type: `tween`,
      ease: sine.out,
      duration: 400,
      delay: 1000,
    },
  },
});

const CardInner = ({
  theme,
  heading,
  title,
  description,
  ctaLabel,
  image,
  hasImage,
}) => (
  <React.Fragment>
    <CardContent initialPose={`inactive`} className={styles.cardInner}>
      {heading && heading.html && heading.html !== "" && heading.text !== ""
        ? renderHtml(heading)
        : renderText(title, "h2")}
      {renderHtml(description)}
      {renderText(
        ctaLabel && ctaLabel.text ? ctaLabel : { text: "Learn more" },
        "span",
        styles.button
      )}
    </CardContent>
    {hasImage && <figure>{renderFluidImage(image)}</figure>}
    <Overlay
      initialPose={`inactive`}
      className={`block-overlay ${cx({ [theme]: true, overlay: true })}`}
    />
  </React.Fragment>
);

export default class CardList extends PureComponent {
  state = { active: false, waypointDisabled: false };
  onWaypointEnter = () => {
    this.setState({ active: true, waypointDisabled: true });
  };
  render() {
    const { primary, items } = this.props;
    const { category } = primary;
    const theme = primary.theme || `grey`;
    return (
      <Section className={`${theme} ${cx({ section: true, [theme]: true })}`}>
        <Waypoint
          onEnter={
            this.state.waypointDisabled ? undefined : this.onWaypointEnter
          }
          bottomOffset={`10%`}
          topOffset={`-10%`}
        >
          <Wrapper pose={this.state.active ? `active` : `inactive`}>
            <Container>
              <Columns mobile multiline>
                <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                  {renderText(category, "h4")}
                </Columns.Column>
                {items
                  .map((item, idx) => {
                    const {
                      link,
                      external_link,
                      description,
                      heading,
                      cta_label,
                      image,
                    } = item;
                    const hasExternalLink = external_link && external_link.url;
                    console.log("heading", heading);
                    let linkData = hasExternalLink
                      ? { url: external_link.url, target: external_link.target }
                      : link
                      ? resolveLinkById(link.raw.id)
                      : null;
                    const hasImage = image && (image.url || image.localFile);
                    let slug = null;
                    let title = null;

                    if (linkData) {
                      slug = linkData.slug;
                      title = linkData.title;
                    }

                    if (!slug && !hasExternalLink) {
                      return null;
                    }

                    if (!hasExternalLink) {
                      slug = slug.replace("//", "/");
                      if (slug.charAt(0) !== "/") {
                        slug = `/${slug}`;
                      }
                    }

                    return (
                      <Columns.Column
                        key={`${slug}-${idx}`}
                        mobile={{ size: 12 }}
                        tablet={{ size: 6 }}
                        desktop={{ size: 6 }}
                        widescreen={{ size: 4 }}
                        className={styles.item}
                      >
                        {slug || hasExternalLink ? (
                          <>
                            {slug && (
                              <Link
                                to={`${slug}`}
                                title={`${title.text}`}
                                className={cx({
                                  card: true,
                                  noImage: !hasImage,
                                })}
                              >
                                <CardInner
                                  active={this.state.active}
                                  theme={theme}
                                  heading={heading}
                                  title={title}
                                  description={description}
                                  ctaLabel={cta_label}
                                  image={image}
                                  hasImage={hasImage}
                                />
                              </Link>
                            )}
                            {hasExternalLink && (
                              <a
                                title={heading}
                                className={cx({
                                  card: true,
                                  noImage: !hasImage,
                                })}
                                href={external_link.url}
                                target={
                                  external_link.target === "_blank"
                                    ? "_blank"
                                    : "_self"
                                }
                              >
                                <CardInner
                                  active={this.state.active}
                                  theme={theme}
                                  heading={heading}
                                  title={heading}
                                  description={description}
                                  ctaLabel={cta_label}
                                  image={image}
                                  hasImage={hasImage}
                                />
                              </a>
                            )}
                          </>
                        ) : (
                          <div
                            className={cx({ card: true, noImage: !hasImage })}
                          >
                            <CardInner
                              active={this.state.active}
                              theme={theme}
                              title={title}
                              heading={heading}
                              description={description}
                              ctaLabel={cta_label}
                              image={image}
                              hasImage={hasImage}
                            />
                          </div>
                        )}
                      </Columns.Column>
                    );
                  })
                  .filter(Boolean)}
              </Columns>
            </Container>
          </Wrapper>
        </Waypoint>
      </Section>
    );
  }
}
