import React from 'react';
import ContentBlock from 'slices/ContentBlock';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';

import classNames from 'classnames/bind';
import styles from './ContentSection.module.scss';
const cx = classNames.bind(styles);

export default props => {
  return (
    <Section
      className={`${props.primary.theme || 'white'} ${cx({
        section: true,
        [props.primary.theme || 'white']: true
      })}`}
    >
      <Container>
        <Columns mobile multiline>
          <ContentBlock {...props} />
        </Columns>
      </Container>
    </Section>
  );
};
