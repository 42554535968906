import React from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import { renderText } from 'utils/renderHelpers';

import styles from './ContentColumns.module.scss';
import classNames from 'classnames/bind';
import uid from 'utils/uid';
import HTMLContent from '../HTMLContent';

const cx = classNames.bind(styles);

export default ({ primary, items }) => {
  const {
    desktop_columns,
    tablet_columns,
    mobile_columns,
    theme,
    heading
  } = primary;

  return (
    <Section className={cx({ section: true, [theme || `white`]: true })}>
      <Container>
        <Columns mobile multiline>
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            {renderText(heading, 'h4', styles.heading)}
          </Columns.Column>
          {items.map(item => {
            const { content } = item;
            return (
              <Columns.Column
                key={`contentcolumn-${uid()}`}
                mobile={{ size: 12 / parseInt(mobile_columns) }}
                tablet={{ size: 12 / parseInt(tablet_columns) }}
                desktop={{ size: 12 / parseInt(desktop_columns) }}
              >
                <HTMLContent html={content} columns={1} />
              </Columns.Column>
            );
          })}
        </Columns>
      </Container>
    </Section>
  );
};
